import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const DrillDownChart = ({ eventData, metrics }) => {

  const [selectedMetrics, setSelectedMetrics] = useState(['daily_ticket_count', 'revenue']);
  const [chartData, setChartData] = useState({ series: [], categories: [] });
  const [xAxisGrouping, setXAxisGrouping] = useState('days_out');
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    updateChartData(selectedMetrics, xAxisGrouping);
  }, [selectedMetrics, xAxisGrouping]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const metricOptions = metrics.map((metric) => {
    const name = Object.keys(metric)[0];
    const value = metric[name];
    return { name, value };
  });

  const handleChange = (event) => {
    const selectedNames = event.target.value;
    setSelectedMetrics(selectedNames);
  };

  const updateChartData = (selectedNames, xGrouping) => {
    let filteredData = eventData;
    const oneMonthAgo = dayjs().subtract(45, 'days').startOf('day');
    const today = dayjs();

    if (xGrouping === 'sale_date') {
      filteredData = eventData.filter(event => dayjs(event.sale_date).isAfter(oneMonthAgo));
    } else if (xGrouping === 'days_out') {
      filteredData = eventData.filter(event => {
        const daysOutDate = dayjs().subtract(event.days_out, 'day');
        return daysOutDate.isAfter(oneMonthAgo) && daysOutDate.isBefore(today);
      });
    }

    // Build a set of all categories from filtered data
    const categoriesSet = new Set();
    filteredData.forEach(event => {
      const category = xGrouping === 'days_out' ? event.days_out : dayjs(event.sale_date).format('MM/DD/YYYY');
      categoriesSet.add(category);
    });

    // Convert set to sorted array
    let categories = Array.from(categoriesSet);
    categories.sort((a, b) => {
      if (xGrouping === 'days_out') {
        return a - b;
      } else {
        return dayjs(a, 'MM/DD/YYYY').unix() - dayjs(b, 'MM/DD/YYYY').unix();
      }
    });

    // Build a data map for quick lookup
    const dataMap = {};
    filteredData.forEach(event => {
      const eventId = event.event_id;
      const category = xGrouping === 'days_out' ? event.days_out : dayjs(event.sale_date).format('MM/DD/YYYY');
      if (!dataMap[eventId]) dataMap[eventId] = {};
      dataMap[eventId][category] = event;
    });

    // Map metrics to y-axis indices
    const metricYAxisMap = {};
    let yAxisIndex = 0;
    selectedNames.forEach(metricName => {
      metricYAxisMap[metricName] = yAxisIndex++;
    });

    // Construct series data aligned with categories, using null for missing data
    const newSeries = [];
    selectedNames.forEach(metricName => {
      const yAxisIdx = metricYAxisMap[metricName];
      Object.keys(dataMap).forEach(eventId => {
        const seriesName = `${metricName.charAt(0).toUpperCase() + metricName.slice(1)} - ${eventId}`;
        const data = categories.map(category => {
          const event = dataMap[eventId][category];
          return event ? (event[metricName] || null) : null;
        });
        newSeries.push({ name: seriesName, data, yAxisIndex: yAxisIdx });
      });
    });

    setChartData({ series: newSeries, categories });
    updateYAxis(newSeries);
  };

  const updateYAxis = (newSeries) => {
    const yAxisConfigs = [];
    const usedMetrics = new Set();

    newSeries.forEach(series => {
      const metricName = series.name.split(' - ')[0].toLowerCase().replace(/ /g, '_');
      const yAxisIdx = series.yAxisIndex;

      if (!usedMetrics.has(metricName)) {
        const yAxisSeries = newSeries.filter(s => s.yAxisIndex === yAxisIdx);
        const allData = yAxisSeries.flatMap(s => s.data.filter(value => value !== null));
        const min = Math.min(...allData);
        const max = Math.max(...allData);

        yAxisConfigs.push({
          seriesName: metricName,
          title: { text: metricName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') },
          min: min >= 0 ? 0 : min,
          max: max,
          opposite: yAxisIdx % 2 === 1,
          labels: {
            formatter: (value) => {
              if (Math.abs(value) >= 1) {
                return Math.round(value).toLocaleString();
              } else if (value !== null) {
                return value.toFixed(2);
              }
            },
          },
        });

        usedMetrics.add(metricName);
      }
    });

    const newChartOptions = {
      chart: {
        id: 'mainChart',
        type: 'line',
        toolbar: { show: true },
        zoom: { enabled: true },
      },
      markers: { size: 3, strokeWidth: 1 },
      stroke: { width: 3, curve: 'smooth', lineCap: 'round' },
      xaxis: {
        title: { text: xAxisGrouping === 'days_out' ? 'Days Out' : 'Sale Date' },
        categories: chartData.categories,
      },
      yaxis: yAxisConfigs,
      title: { text: 'Ticket Sales Over Time', align: 'left' },
      legend: { position: 'top', horizontalAlign: 'left' },
    };

    setChartOptions(newChartOptions);
  };

  const handleXAxisChange = (event) => {
    const selectedValue = event.target.value;
    setXAxisGrouping(selectedValue);
  };

  useEffect(() => {
    updateChartData(selectedMetrics, xAxisGrouping);
  }, [eventData, selectedMetrics, xAxisGrouping]);  

  return (
    <div>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <FormControl sx={{ mt: 10, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Metrics</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedMetrics}
              onChange={handleChange}
              input={<OutlinedInput label="Metric" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {metricOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox checked={selectedMetrics.includes(option.name)} />
                  <ListItemText primary={option.name.split("_").map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(" ")} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 10, width: 200 }}>
            <InputLabel id="x-axis-grouping-label">X-Axis Grouping</InputLabel>
            <Select
              labelId="x-axis-grouping-label"
              value={xAxisGrouping}
              onChange={handleXAxisChange}
              input={<OutlinedInput label="X-Axis Grouping" />}
            >
              <MenuItem value="days_out">Days Out</MenuItem>
              <MenuItem value="sale_date">Sale Date</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Chart options={chartOptions} series={chartData.series} type="line" height={450} style={{ marginTop: 20 }} />
      </Box>
    </div>
  );
};

export default DrillDownChart;