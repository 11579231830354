import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Paper, TextField, Button, Autocomplete } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SkeletonLoader from './SkeletonLoader';
import { useTicketureAllEventIDs, useTicketureByOpenDate } from '../middlewares/ticketure';
import { fetchMetaInsigthByEventIDs, fetchTicketureByEventIDs } from '../services/ticketure';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/drilldownSlice';
import dayjs from 'dayjs';
import DrillDownChart from './UI-components/drilldown/chart';

  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: (params) => {
      const value = params || 0;
      const wholeNumValue = Number(value).toFixed(0) 
      return `$${Number(wholeNumValue).toLocaleString()}`;
    },
  };

  const largeNumber = {
    type: 'number',
    width: 130,
    valueFormatter: (params) => {
      const value = params || 0;
      const wholeNumValue = Number(value).toFixed(0);
      return Number(wholeNumValue).toLocaleString();
    },
  };

  const decimalNumber = {
    type: 'number',
    width: 90
  };

const Drilldown = () => {
  const columns = [
    { field: 'week_number', headerName: "Week Number", type: 'number', headerAlign: 'center', align: 'center' },
    { field: 'day_of_week', headerName: "Day of Week", type: 'string', headerAlign: 'center', align: 'center' },
    { field: 'sale_date', headerName: "Sale Date", type: 'string', headerAlign: 'center', align: 'center' },
    { field: 'event_id', headerName: "Event ID", type: 'string', headerAlign: 'center', align: 'center' },
    { field: 'market_name', headerName: "Market Name", type: 'string', headerAlign: 'center', align: 'center' },
    { field: 'median_income', headerName: "Median Income", ...usdPrice, headerAlign: 'center', align: 'center' },
    { field: 'digital_audience_size', headerName: "Digital Audience Size", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'days_out', headerName: "Days Out", type: 'number', headerAlign: 'center', align: 'center' },
    { field: 'marketing_phase', headerName: "Marketing Phase", type: 'string', headerAlign: 'center', align: 'center' },
    { field: 'daily_ticket_count', headerName: "Daily Ticket Count", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'revenue', headerName: "Daily Revenue", ...usdPrice, headerAlign: 'center', align: 'center' },
    { field: 'cumulative_ticket_count', headerName: "Total Tickets", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'total_revenue', headerName: "Total Revenue", ...usdPrice, headerAlign: 'center', align: 'center' },
    { field: 'running_avg_daily_tickets', headerName: "Avg Tickets", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'spend', headerName: "Spend", ...usdPrice, headerAlign: 'center', align: 'center' },
    { field: 'cumulative_spend', headerName: "Total Spend", ...usdPrice, headerAlign: 'center', align: 'center' },
    { field: 'roas', headerName: "ROAS", type: 'number', headerAlign: 'center', align: 'center', valueFormatter: (params) => `${(params || 0).toFixed(0).toLocaleString()} %` },
    { field: 'impressions', headerName: "Impressions", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'clicks', headerName: "Clicks", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'unique_clicks', headerName: "Unique Clicks", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'inline_post_engagement', headerName: "Engagements", ...largeNumber, headerAlign: 'center', align: 'center' },
    { field: 'frequency', headerName: "Frequency", type: 'number', headerAlign: 'center', align: 'center', ...decimalNumber, valueFormatter: (params) => (params || 0).toFixed(2) },
    { field: 'cpt', headerName: 'CPT', type: 'number', headerAlign: 'center', align: 'center', ...decimalNumber },
    { field: 'ctr', headerName: "CTR", type: 'number', headerAlign: 'center', align: 'center', ...decimalNumber },
    { field: 'cpm', headerName: "CPM", type: 'number', headerAlign: 'center', align: 'center', ...decimalNumber },
    { field: 'cpe', headerName: "CPE", type: 'number', headerAlign: 'center', align: 'center', ...decimalNumber },
  ];

  const [selectedEventIDs, setSelectedEventIDs] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [appliedEventIDs, setAppliedEventIDs] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [error, setError] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    week_number: false,
    day_of_week: false,
    median_income: false,
    digital_audience_size: false,
  });

  const { data: allEventIDs } = useTicketureAllEventIDs();
  const { data: staticData } = useQuery("forecastData", async () => {
    const response = await axios.get("https://fqe-analytics-server-e1c19d4ce6cf.herokuapp.com/api/forecast-data");
    return response.data;
  });

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.drilldown.loading);

  // Date calculations
  const today = dayjs();
  const startDate = today.format('YYYY-MM-DD');
  const endDate = today.add(2, 'week').format('YYYY-MM-DD');

  // Fetch events within the date range
  const { data: ticketureData, error: ticketureError, isLoading: ticketureLoading } = useTicketureByOpenDate(startDate, endDate);

  // Utility function to ensure uniqueness
  const getUniqueDataByMaxCumulativeTicketCount = (data) => {
    const map = new Map();
    data.forEach((item) => {
      const key = `${item.event_id}-${item.sale_date}`;
      if (!map.has(key) || map.get(key).cumulative_ticket_count < item.cumulative_ticket_count) {
        map.set(key, item);
      }
    });
    return Array.from(map.values());
  };

  // Helper function to normalize strings
  const normalizeString = (str) => str.trim().toLowerCase();

  // Set default event IDs based on events within the date range
  useEffect(() => {
    if (ticketureData && !ticketureLoading) {
      const eventIDs = Array.from(new Set(ticketureData.map(event => event.event_id)));
      setSelectedEventIDs(eventIDs);
      setAppliedEventIDs(eventIDs);
    }
  }, [ticketureData]);

  useEffect(() => {
    if (appliedEventIDs.length > 0) {
      const fetchAllEventData = async () => {
        setError(null);
        try {
          dispatch(setLoading({ isLoading: true, type: "dailyTicketSalesMetaInsight" }));
          const allEventData1 = await Promise.all(
            appliedEventIDs.map((eventID) => dispatch(fetchTicketureByEventIDs(eventID)))
          );
          const allEventData2 = await Promise.all(
            appliedEventIDs.map((eventID) => dispatch(fetchMetaInsigthByEventIDs(eventID)))
          );
          const rawData1 = allEventData1.flatMap((response) => response.payload);
          const rawData2 = allEventData2.flatMap((response) => response.payload);

          const filterArr = rawData1.map((elem) => {
            const match = rawData2.find(
              (elem2) => elem.event_id === elem2.event_id && elem.sale_date === elem2.date_start
            );
            const combinedItem = { ...elem, ...(match || {}) };
          
            // Calculate marketing_phase with fallback if days_out is missing
            const daysOut = combinedItem.days_out ?? null;
            let marketing_phase = null;
          
            if (daysOut !== null) {
              if (daysOut >= 121) marketing_phase = 1;
              else if (daysOut <= 120 && daysOut >= 31) marketing_phase = 2;
              else if (daysOut <= 30 && daysOut >= 15) marketing_phase = 3;
              else if (daysOut <= 14 && daysOut >= 8) marketing_phase = 4;
              else if (daysOut <= 7 && daysOut >= -5) marketing_phase = 5;
            }
            combinedItem.marketing_phase = marketing_phase;
          
            // Date calculations with fallback if sale_date is missing
            const saleDate = combinedItem.sale_date ? dayjs(combinedItem.sale_date) : null;
            combinedItem.week_number = saleDate ? saleDate.week() : null;
            combinedItem.day_of_week = saleDate ? saleDate.format('dddd') : null;
          
            return combinedItem;
          }).filter(Boolean);

          // Remove duplicates, keep item with largest cumulative_ticket_count
          const uniqueFilterArr = getUniqueDataByMaxCumulativeTicketCount(filterArr);

          // Sort the data by event_id and sale_date ascending
          uniqueFilterArr.sort((a, b) => {
            if (a.event_id === b.event_id) {
              return dayjs(a.sale_date).valueOf() - dayjs(b.sale_date).valueOf();
            } else {
              return a.event_id.localeCompare(b.event_id);
            }
          });

          // Initialize cumulative variables per event_id
          const cumulativeRevenuePerEvent = {};
          const cumulativeSpendPerEvent = {};
          const firstSaleDatePerEvent = {};
          const staticDataMap = {};
          staticData.forEach((item) => {
            const clusterMarket = item["Cluster Market"];
            if (clusterMarket) {
              const normalizedClusterMarket = normalizeString(clusterMarket);
              staticDataMap[normalizedClusterMarket] = item;
            }
          });

          // Iterate over the sorted data to calculate cumulative values per event_id
          const dataWithCalculations = uniqueFilterArr.map((item) => {
            const eventId = item.event_id;

            // Initialize cumulative revenue and spend for the event_id if not already done
            if (!cumulativeRevenuePerEvent[eventId]) {
              cumulativeRevenuePerEvent[eventId] = 0;
            }
            if (!cumulativeSpendPerEvent[eventId]) {
              cumulativeSpendPerEvent[eventId] = 0;
            }

            // Set the first sale date per event_id
            if (!firstSaleDatePerEvent[eventId]) {
              firstSaleDatePerEvent[eventId] = dayjs(item.sale_date);
            }

            // Accumulate revenue and spend
            cumulativeRevenuePerEvent[eventId] += Number(item.revenue) || 0;
            cumulativeSpendPerEvent[eventId] += Number(item.spend) || 0;

            // Calculate days since first sale (inclusive)
            const daysSinceFirstSale = dayjs(item.sale_date).diff(firstSaleDatePerEvent[eventId], 'day') + 1;

            // Calculate running average using cumulative_ticket_count
            const runningAverageTicketsSold = parseFloat(
              (Number(item.cumulative_ticket_count) / daysSinceFirstSale).toFixed(2)
            );

            // Calculate Cost Per Ticket (CPT) using cumulative values
            let cpt = null;
            const cumulativeTicketsSold = Number(item.cumulative_ticket_count) || 0;
            const cumulativeSpend = cumulativeSpendPerEvent[eventId];

            if (cumulativeTicketsSold > 0) {
              cpt = cumulativeSpend / cumulativeTicketsSold;
            } else if (cumulativeTicketsSold === 0 && cumulativeSpend > 0) {
              cpt = Infinity;
            } else {
              cpt = 0;
            }

            // Format CPT to fixed decimal places
            cpt = parseFloat(cpt.toFixed(2));

            // Calculate ROAS
            const roas = cumulativeRevenuePerEvent[eventId] / cumulativeSpendPerEvent[eventId]

            // Map static data based on market_name matching "Cluster Market"
            const marketName = item.market_name;
            const normalizedMarketName = normalizeString(marketName);
            const staticItem = staticDataMap[normalizedMarketName];

            // Initialize variables to hold the static data values
            let medianIncome = null;
            let digitalAudienceSize = null;

            if (staticItem) {
              medianIncome = staticItem["Median Income ($)"];
              digitalAudienceSize = staticItem["Digital Audience Size (20 Mile)"];
            } else {
              console.warn(`No static data found for market: ${marketName}`);
            }

            return {
              ...item,
              total_revenue: cumulativeRevenuePerEvent[eventId],
              cumulative_spend: cumulativeSpend.toFixed(0),
              running_avg_daily_tickets: runningAverageTicketsSold,
              roas: roas * 100,
              cpt,
              median_income: medianIncome,
              digital_audience_size: digitalAudienceSize,
            };
          });

          setEventData(dataWithCalculations);
          dispatch(setLoading({ isLoading: false, type: "" }))
        } catch (error) {
          setError(error);
          dispatch(setLoading({ isLoading: false, type: "" }));
        }
      };
      fetchAllEventData();
    }
  }, [dispatch, appliedEventIDs]);

  const handleApply = () => {
    setAppliedEventIDs([...selectedEventIDs]);
  };

  const handleExportCSV = () => {
    const csvRows = [];
    const headers = [
      'week_number',
      'day_of_week',
      'sale_date',
      'event_id',
      'market_name',
      'median_income',
      'digital_audience_size',
      'days_out',
      'marketing_phase',
      'daily_ticket_count',
      'revenue',
      'total_revenue',
      'cumulative_spend',
      'roas',
      'running_avg_daily_tickets',
      'cost_per_ticket',
      'cumulative_ticket_count',
      'spend',
      'impressions',
      'clicks',
      'unique_clicks',
      'inline_post_engagement',
      'frequency',
      'cpt',
      'ctr',
      'cpm',
      'cpe',
    ];
    csvRows.push(headers.join(','));

    eventData.forEach((row) => {
      const values = [
        row.week_number,
        row.day_of_week,
        row.sale_date,
        row.event_id,
        row.market_name,
        row.median_income,
        row.digital_audience_size,
        row.days_out,
        row.marketing_phase,
        row.daily_ticket_count,
        row.revenue,
        row.total_revenue,
        row.cumulative_spend,
        row.roas,
        row.running_avg_daily_tickets,
        row.cpt,
        row.cumulative_ticket_count,
        row.spend,
        row.impressions,
        row.clicks,
        row.unique_clicks,
        row.inline_post_engagement,
        row.frequency,
        row.cpt,
        row.ctr,
        row.cpm,
        row.cpe,
      ];

      // Map over values to handle commas and quotes
      const formattedValues = values.map((value) => {
        if (value === null || value === undefined) {
          return '';
        }
        const stringValue = value.toString();
        if (/[",\n]/.test(stringValue)) {
          const escapedValue = stringValue.replace(/"/g, '""');
          return `"${escapedValue}"`;
        }
        return stringValue;
      });
      csvRows.push(formattedValues.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'drilldown.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const totals = eventData?.map((f) => ({
      revenue: f.revenue,
      impressions: f.impressions,
      cumulative_spend: Math.floor(Number(f.cumulative_spend)),
    }))?.reduce((acc, item) => {
      return {
        revenue: acc.revenue + item.revenue,
        impressions: acc.impressions + item.impressions,
        cumulative_spend: acc.cumulative_spend + item.cumulative_spend,
        daily_ticket_count: acc.daily_ticket_count + item.daily_ticket_count,
        total_revenue: acc.total_revenue + item.total_revenue,
        spend: acc.spend + item.spend,
        clicks: acc.clicks + item.clicks,
        unique_clicks: acc.unique_clicks + item.unique_clicks
      };
    }, {
      revenue: 0,
      impressions: 0,
      cumulative_spend: 0,
      daily_ticket_count: 0,
      total_revenue: 0,
      spend: 0,
      clicks: 0,
      unique_clicks: 0
    });
    const arr = Object.entries(totals).map(([k, v]) => ({ [k]: v }))
    setMetrics(arr)
  }, [eventData])

  return (
    <Paper style={{ padding: '30px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            multiple
            options={allEventIDs || []}
            getOptionLabel={(option) => option}
            value={selectedEventIDs}
            onChange={(event, newValue) => setSelectedEventIDs(newValue)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select Event IDs" />
            )}
            style={{ width: '1000px', marginRight: '20px' }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100px', fontWeight: '900', marginRight: '20px', maxHeight: '50px' }}
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleExportCSV}
            style={{ fontWeight: '900', marginRight: '20px', maxHeight: '50px' }}
          >
            Export CSV
          </Button>
        </div>
      </div>
      {loading.isLoading && loading.type === 'dailyTicketSalesMetaInsight' ? (
        <SkeletonLoader loading={loading.isLoading} width="100%" height={450} />
      ) : (
        <DrillDownChart eventData={eventData} metrics={metrics} />
      )}
      {loading.isLoading && loading.type === 'dailyTicketSalesMetaInsight' ? (
        <SkeletonLoader loading={loading.isLoading} width="100%" height={450} />
      ) : (
        <div style={{ height: "70vh", width: '100%', overflowX: "auto", position: "relative" }}>
          <DataGrid
            columns={columns}
            rows={eventData}
            pageSize={5}
            slots={{ toolbar: GridToolbar }}
            style={{ width: "100%", minWidth: '600px', position: "absolute" }}
            showCellVerticalBorder
            showColumnVerticalBorder
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </div>
      )}
    </Paper>
  );
};

export default Drilldown;
