import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Switch, Autocomplete, TextField, Button, Box, Card } from '@mui/material';
import { fetchTicketureAllEventIDs, fetchTicketureByEventIDs, fetchTicketureByOpenDate } from '../services/ticketure';
import SkeletonLoader from './SkeletonLoader';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const Dashboard = () => {

  const [selectedEventIDs, setSelectedEventIDs] = useState([]);
  const [appliedEventIDs, setAppliedEventIDs] = useState([]);
  const [showCumulative, setShowCumulative] = useState(false);
  const [chartData, setChartData] = useState({ series: [] });
  const [eventData, setEventData] = useState([])

  const dispatch = useDispatch();
  const state = useSelector((state) => state.ticketure);
  const companyName = useSelector((state) => state.company.company);
  const startDate = dayjs().startOf('week').format('YYYY-MM-DD');
  const endDate = dayjs().endOf('week').format('YYYY-MM-DD');

  // Fetch event data based on companyName
  useEffect(() => {
    setChartData({ series: [] }); // Clear the chart when company changes
    dispatch(fetchTicketureAllEventIDs());
    dispatch(fetchTicketureByOpenDate({ startDate, endDate }));
  }, [dispatch, startDate, endDate, companyName]);

  // Set applied event IDs
  useEffect(() => {
    if (state?.defaultEventIDs && state?.defaultEventIDs.length > 0) {
      const uniqueEventIDs = Array.from(new Set(state?.defaultEventIDs.map(event => event.event_id)));
      setAppliedEventIDs(uniqueEventIDs);
    }
  }, [state?.defaultEventIDs]);

  // Fetch data for the applied event IDs
  useEffect(() => {
    if (appliedEventIDs.length > 0) {
      dispatch(fetchTicketureByEventIDs(appliedEventIDs));
    }
  }, [dispatch, appliedEventIDs, companyName]);

  // Process chart data and handle multiple event IDs
  useEffect(() => {
    if (state?.eventData && state?.eventData.length > 0) {
      const rawData = eventData;
      const processedData = processChartData(rawData);
      setChartData(processedData);
    }
  }, [state?.eventData, showCumulative]);

  const handleToggleChange = () => {
    setShowCumulative(!showCumulative);
  };

  useEffect(() => {
    if (appliedEventIDs.length > 0) {
      const fetchAllEventData = async () => {
        const allEventData = await Promise.all(appliedEventIDs.map(eventID => dispatch(fetchTicketureByEventIDs(eventID))));
        const rawData = allEventData.flatMap((response) => response.payload);
        const processedData = processChartData(rawData);
        setChartData(processedData);
        setEventData(rawData);
      };
      fetchAllEventData();
    }
  }, [dispatch, appliedEventIDs, companyName]);

  const processChartData = (data) => {
    if (!data) return { series: [] };

    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.event_id]) {
        acc[item.event_id] = new Map();
      }
      const yValue = showCumulative ? item.cumulative_ticket_count : item.daily_ticket_count;
      acc[item.event_id].set(item.days_out, {
        y: yValue,
        sale_date: item.sale_date,
      });
      return acc;
    }, {});

    const series = Object.keys(groupedData).map(event_id => {
      const sortedData = Array.from(groupedData[event_id].entries())
        .sort((a, b) => a[0] - b[0])
        .map(([x, { y, sale_date }]) => ({ x, y, sale_date }));

      return {
        name: event_id,
        data: sortedData,
      };
    });

    return { series };
  };

  const handleApply = () => {
    setAppliedEventIDs([...selectedEventIDs]);
  };

  const chartOptions = {
    chart: {
      id: 'mainChart',
      type: 'line',
      toolbar: { show: true },
      zoom: { enabled: false },
    },
    markers: { size: 3, strokeWidth: 1 },
    stroke: { width: 3, curve: 'smooth', lineCap: 'round' },
    xaxis: {
      title: { text: 'Days Out' },
      type: 'numeric',
      labels: { formatter: (value) => Math.floor(value) },
      min: -2,
      max: 60,
    },
    yaxis: {
      title: { text: showCumulative ? 'Cumulative Ticket Count' : 'Daily Ticket Count' },
      min: 0,
    },
    title: { text: 'Ticket Sales Over Time', align: 'left' },
    legend: { position: 'top', horizontalAlign: 'left' },
    tooltip: {
      enabled: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
        const daysOut = dataPoint.x;
        const ticketCount = dataPoint.y;
        const saleDate = dataPoint.sale_date;
  
        return `
          <div style="padding: 10px; border: 1px solid #ccc; background: white;">
            <strong>Event: ${w.config.series[seriesIndex].name}</strong><br />
            Days Out: ${daysOut}<br />
            Sale Date: ${saleDate}<br />
            Ticket Count: ${ticketCount}
          </div>
        `;
      },
    },
  };

  const brushChartOptions = {
    chart: {
      id: 'brushChart',
      height: 130,
      type: 'area',
      brush: {
        target: 'mainChart',
        enabled: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: -2,
          max: 60,
        },
      },
    },
    xaxis: {
      type: 'numeric',
      labels: { formatter: (value) => Math.floor(value) },
    },
    yaxis: {
      tickAmount: 2,
    },
  };

  return (
    <div style={{ margin: '10px 30px', background: '#F1F5F9' }}>
      <Card>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <div style={{ padding: '10px' }}>
              <FormControlLabel control={<Switch checked={showCumulative} onChange={handleToggleChange} />} label="Show Cumulative Sales" />
            </div>
            <Autocomplete
              multiple
              options={state?.allEventIds || []}
              getOptionLabel={option => option}
              value={selectedEventIDs}
              onChange={(event, newValue) => setSelectedEventIDs(newValue)}
              renderInput={params => <TextField {...params} variant="outlined" label="Select Event IDs" />}
              style={{ width: '70%' }}
            />
            <Button variant="contained" color="primary" onClick={handleApply}>Apply</Button>
          </div>
          {state.loading || !state?.eventData ? (
            <SkeletonLoader loading={state?.loading || !state?.eventData} width="100%" height={450} />
          ) : (
            <>
              <Chart options={chartOptions} series={chartData.series} type="line" height={450} style={{ marginTop: 20 }} />
              <Chart options={brushChartOptions} series={chartData.series} type="area" height={130} />
            </>
          )}
        </Box>
      </Card>
    </div>
  );
};

export default Dashboard;